import * as React from "react";
import { Component } from "react";

class App extends Component {
    componentDidMount() {
        // Get OS and Redirect
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            let customSchemaUrl = process.env.GATSBY_MOBILE_APP_CUSTOM_SCHEMA + window.location.search;
            console.log('Android device -> open app if installed. CustomSchemaUrl: ' + customSchemaUrl);
            window.location.href = customSchemaUrl;
            setTimeout(function () {
                console.log('App not installed -> redirect to Play Store');
                window.location.replace("https://play.google.com/store/apps/details?id=com.pirelli.care");
            }, 1000);

            return;
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            let customSchemaUrl = process.env.GATSBY_MOBILE_APP_CUSTOM_SCHEMA + window.location.search;
            console.log('iOS device -> open app if installed. CustomSchemaUrl: ' + customSchemaUrl);
            window.location.href = customSchemaUrl;
            setTimeout(function () {
                console.log('App not installed -> redirect to App Store');
                window.location.replace("https://apps.apple.com/app/pirelli-care/id1567220418");
            }, 1000);

            return;
        }

        console.log('Not a mobile device -> redirect to onboarding portal');
        window.location.replace(process.env.GATSBY_ONBOARDING_PORTAL_LINK + '/register');
    }

    render() {
        // no HTML to show here
        return <></>
    }
}

export default App;
